import axios from 'axios';
import { getAuthToken, getPortalAuthToken, getResetPasswrodReturnUrl, logoutHandler, verifyIdleLogout } from './utils';

const BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;
const getConnectedStatusEndpoint = '/StripeConnect/GetConnectStatus';

export const getInstance = (Authorization, contentType, responseType) => {
    return axios.create({
        baseURL: BASE_URL,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': contentType,
            ...(Authorization ? { Authorization } : {}),
        },
        ...(responseType ? { responseType } : {}),
    });
}

const handleResponse = ({ data }) => {
    if (data) {
        if (data.status === 0) {  //data.status === 5
            return { code: 400, error: data.message };
        } else {
            return { code: 200, data: data.payload, message: data.message };
        }
    }
}

const handleErrorResponse = ({ request, response, message }) => {
    if (response) {
        if (response.status == 401) {
            const customHistory = { push: (path) => { window.location = path } };
            logoutHandler(customHistory);
        }
        return { code: response.status, error: response.data?.title || response.statusText || response.message };
    } else if (request) {
        return { code: 500, error: 'Unable to send request. Try again later!' };
    } else {
        return { code: 500, error: message };
    }
}

const sendPostRequest = async (url, data) => {
    try {
        const response = await axios.post(BASE_URL + url, data);
        return handleResponse(response);
    } catch (error) {
        return handleErrorResponse(error)
    }
}

const sendPostRequestWithPortalToken = async (url, data, contentType) => {
    try {
        verifyIdleLogout();
        const res = await getInstance(getPortalAuthToken(), contentType).post(url, data);
        return handleResponse(res);
    } catch (error) {
        return handleErrorResponse(error)
    }
}


const sendPostRequestWithToken = async (url, data, contentType, skipToken) => {
    try {
        verifyIdleLogout();
        const res = await getInstance(skipToken ? null : getAuthToken(), contentType).post(url, data);
        return handleResponse(res);
    } catch (error) {
        return handleErrorResponse(error)
    }
}


const sendgetRequestWithToken = async (url) => {
    try {
        if (url !== getConnectedStatusEndpoint) {
            verifyIdleLogout();
        }
        const res = await getInstance(getAuthToken()).get(url);
        return handleResponse(res);
    } catch (error) {
        return handleErrorResponse(error)
    }
}

export const returnProxyGetUrl = (url) => {
    let prefix = BASE_URL + '/misc/ProxyGet';
    if (url && url.startsWith(prefix))
        return url;
    return prefix + '?url=' + encodeURIComponent(url);
}

//studio
export const directContractGetDetail = async (data, tokenId) => {
    return await sendPostRequest('/studio/Direct/ContractGetDetail?token=' + tokenId, data);
}

export const directContractAddSignature = async (data, tokenId) => {
    return await sendPostRequest('/studio/Direct/ContractAddSignature?token=' + tokenId, data);
}

export const directEmbeddedFormGetDetail = async (data, tokenId) => {
    return await sendPostRequest('/studio/Direct/EmbeddedFormGetDetail?token=' + tokenId, data);
}

export const directEmbeddedFormResponse = async (data, tokenId) => {
    return await sendPostRequest('/studio/Direct/EmbeddedFormResponse?token=' + tokenId, data);
}


export const directQuestionnaireGetDetail = async (data, tokenId) => {
    return await sendPostRequest('/studio/Direct/QuestionnaireGetDetail?token=' + tokenId, data);
}

export const directQuestionnaireSubmit = async (data, tokenId) => {
    return await sendPostRequest('/studio/Direct/QuestionnaireSubmit?token=' + tokenId, data);
}




//
export const signupUser = async (data) => {
    return await sendPostRequest('/auth/SignUp', data);
}

export const completeSignUpV2 = async (data) => {
    return await sendPostRequest('/auth/CompleteSignUpV2', data);
}

export const resendEmailPinCode = async (data) => {
    return await sendPostRequest('/auth/ResendEmailPinCode', data);
}

export const signupV2 = async (data) => {
    return await sendPostRequest('/auth/SignUpV2', data);
}

export const validateEmailPinCode = async (data) => {
    return await sendPostRequest('/auth/ValidateEmailPinCode', data);
}


export const sendEmailVerificationCode = async (data) => {
    return await sendgetRequestWithToken('/auth/SendEmailVerificationCode');
}

export const verifyEmailVerificationCode = async (code) => {
    return await sendgetRequestWithToken(`/auth/VerifyEmailCode?code=${code}`);
}

export const switchCompany = async (companyId) => {
    return await sendPostRequestWithToken(`/auth/SwitchCompany?companyId=${companyId}`);
}

export const signInUser = async (data) => {
    return await sendPostRequest('/auth/login', data);
}

export const externalSignIn = async (data) => {
    return await sendPostRequest('/ExternalAuth/LoginExternal', data);
}

export const createStandardStripe = async (data) => {
    return await sendPostRequest('/auth/CreateStandardStripe', data);
}

export const loginWithToken = async (token) => {
    try {
        const res = await getInstance(token).post('auth/loginwithtoken');
        return handleResponse(res);
    } catch (error) {
        return handleErrorResponse(error)
    }
}

export const connectWithGoogleExternalInt = async (data) => {
    return await sendPostRequestWithToken('ExternalIntegration/ConnectWithGoogle', data);
}

export const syncGoogleCalendarEvents = async (data) => {
    return await sendPostRequestWithToken('CalendarEvent/SyncGoogleCalendarEvents', data);
}


export const completeProfile = async (data) => {
    return await sendPostRequestWithToken('/auth/UpdateProfileDetail', data);
}

export const getStripeConnectionStatus = async () => {
    return await sendgetRequestWithToken(getConnectedStatusEndpoint);
}

export const getStripePlanDetail = async (isLinkedAccountRequired = false) => {
    return await sendgetRequestWithToken(`/StripeConnect/GetStripePlanDetail?isLinkedAccountRequired=${isLinkedAccountRequired}`);
}

export const reverifyAccount = async (data) => {
    return await sendPostRequestWithToken('/StripeConnect/ReverifyAccount', data);
}

export const lookupData = async () => {
    return await sendgetRequestWithToken('/LookupData/GetData');
}

export const getRecipientsAndContacts = async (data) => {
    return await sendPostRequestWithToken(`/Contacts/GetAll`, data);
}

export const addNewCustomer = async (data) => {
    return await sendPostRequestWithToken('/CustomerStripe/add', data);
}

export const getCustomer = async (data) => {
    return await sendPostRequestWithToken('/CustomerStripe/get', data);
}

export const getContractById = async (data) => {
    return await sendPostRequestWithToken('/Contacts/Get', data);
}

export const getContactEmails = async (data) => {
    return await sendPostRequestWithToken('/contacts/GetContactEmails', data);
}

export const addContact = async (data, forceValidation = false) => {
    return await sendPostRequestWithToken(`/Contacts/Add?forceValidation=${forceValidation}`, data);
}

export const updateContact = async (data) => {
    return await sendPostRequestWithToken('/Contacts/Update', data);
}

export const updateCustomer = async (data) => {
    return await sendPostRequestWithToken('/CustomerStripe/update', data);
}

export const deleteCustomer = async (data) => {
    return await sendPostRequestWithToken('/CustomerStripe/remove', data);
}

export const deleteContact = async (data) => {
    return await sendPostRequestWithToken('/Contacts/Delete', data);
}


export const deleteBulkRecipients = async (data) => {
    return await sendPostRequestWithToken('/TransactionSend/BulkDeleteRecipient', data);
}

export const uploadProductImage = async (data) => {
    return await sendPostRequestWithToken('/misc/PostUserImage', data, 'multipart/form-data');
}

export const uploadTokenedImage = async (data, token) => {
    return await sendPostRequestWithToken(`/misc/PostUserImageTokened?myNewToken=${encodeURIComponent(token)}`, data, 'multipart/form-data');
}


export const getCouponsData = async (pageSize, lastId, archived) => {
    return await sendgetRequestWithToken(`/ProductCouponStripe/GetList?pageSize=${pageSize}&lastid=${lastId}&archived=${archived}`);
}

export const getTaxRateList = async (pageSize, lastId, archived) => {
    return await sendgetRequestWithToken(`/ProductTaxRateStripe/GetList?pageSize=${pageSize}&lastid=${lastId}`);
}

export const removeCouponsData = async (data) => {
    return await sendPostRequestWithToken(`/ProductCouponStripe/remove`, data);
}

export const archiveTaxRate = async (data) => {
    return await sendPostRequestWithToken(`/ProductTaxRateStripe/remove`, data);
}

export const archiveBulkTaxRate = async (data) => {
    return await sendPostRequestWithToken(`/ProductTaxRateStripe/BulkRemove`, data);
}

export const addTaxRateValues = async (data) => {
    return await sendPostRequestWithToken(`/ProductTaxRateStripe/Add`, data);
}

export const updateTaxRateValues = async (data) => {
    return await sendPostRequestWithToken(`/ProductTaxRateStripe/update`, data);
}

export const deleteBulkCoupons = async (data) => {
    return await sendPostRequestWithToken(`/ProductCouponStripe/BulkRemove`, data);
}

export const getSingleCoupon = async (data) => {
    return await sendPostRequestWithToken(`/ProductCouponStripe/get`, data);
}

export const addCouponsData = async (data) => {
    return await sendPostRequestWithToken(`/ProductCouponStripe/Add`, data);
}

export const updateCouponData = async (data) => {
    return await sendPostRequestWithToken(`/ProductCouponStripe/update`, data);
}

export const getProductsData = async (pageSize, page, archived, sort, sortOrder) => {
    return await sendgetRequestWithToken(`/ProductStripe/GetList?pageSize=${pageSize}&page=${page}&archived=${archived}&sort=${sort}&sortOrder=${sortOrder}`);
}

export const addProductsData = async (data) => {
    return await sendPostRequestWithToken(`/ProductStripe/Add`, data);
}

export const removeProductsData = async (data) => {
    return await sendPostRequestWithToken(`/ProductStripe/remove`, data);
}

export const getSingleProduct = async (data) => {
    return await sendPostRequestWithToken(`/ProductStripe/get`, data);
}

export const updateProductData = async (data) => {
    return await sendPostRequestWithToken(`/ProductStripe/update`, data);
}


export const getUserData = async () => {
    return await sendgetRequestWithToken(`/auth/GetUserData`);
}

//
export const getAccountingDocumentsByIds = async (ids) => {
    return await sendPostRequestWithToken(`/DocumentFile/GetDocuments`, ids);
}


export const getAccountingDocuments = async (data) => {
    return await sendPostRequestWithToken(`/DocumentFile/GetList`, data);
}

export const deleteAccountingDocuments = async (ids) => {
    return await sendPostRequestWithToken(`/DocumentFile/DeleteDocuments`, ids);
}

export const accountingDocumentAttacher = async (ids) => {
    return await sendPostRequestWithToken(`/DocumentFile/SaveTransactionAttachment`, ids);
}

export const getDocumentTransactions = async (id) => {
    return await sendPostRequestWithToken(`/DocumentFile/GetDocumentTransactions?documentFileId=${id}`, {});
}

export const uploadAccountingDocuments = async (data) => {
    return await sendPostRequestWithToken(`/DocumentFile/PostDocuments`, data);
}

export const uploadStudioDocuments = async (data) => {
    return await sendPostRequestWithToken(`/DocumentFile/PostDocument`, data);
}

export const updateAccountDocumentMetadata = async (data) => {
    return await sendPostRequestWithToken(`/DocumentFile/UpdateDocumentMetadata`, data);
}

export const getAllUserConversations = async (intercomUserId) => {
    return await sendgetRequestWithToken(`/Intercom/GetAllUserConversations?intercomUserId=${intercomUserId}`);
}

export const ensureIntercomContact = async () => {
    return await sendgetRequestWithToken('/Intercom/EnsureContact');
}

export const createConversation = async (intercomUserId, text) => {
    return await sendPostRequestWithToken(`/Intercom/CreateConversation`, { IntercomUserId: intercomUserId, Text: text });
}

export const replyToConversation = async (intercomUserId, conversationId, text) => {
    return await sendPostRequestWithToken(`/Intercom/ReplyConversation`, { ConversationId: conversationId, IntercomUserId: intercomUserId, Text: text });
}

export const retrieveConversation = async (intercomUserId, conversationId) => {
    return await sendgetRequestWithToken(`/Intercom/RetrieveConversation?intercomUserId=${intercomUserId}&conversationId=${conversationId}`);
}

export const updateUserProfile = async (data) => {
    return await sendPostRequestWithToken(`/Settings/UpdateUserProfile`, data);
}

export const updateUserProfileMobile = async (data) => {
    return await sendPostRequestWithToken(`/Settings/UpdateProfileMobile`, data);
}


export const updatePassword = async (data) => {
    return await sendPostRequestWithToken(`/Settings/ChangePassword`, data);
}

export const getAccountDetail = async () => {
    return await sendgetRequestWithToken(`/Settings/GetAccountDetail`);
}

export const updateAccount = async (data) => {
    return await sendPostRequestWithToken(`/Settings/UpdateAccount`, data);
}

export const getAllTeam = async () => {
    return await sendgetRequestWithToken(`/Settings/TeamGetAll`,);
}

export const getAllTeam_TodoTask = async () => {
    return await sendgetRequestWithToken(`/ToDoTask/TeamGetAll`,);
}

export const teamUpdateMemberRole = async (data) => {
    return await sendPostRequestWithToken(`/Settings/TeamUpdateMemberRole`, data);
}

export const deleteTeamMember = async (userid) => {
    return await sendgetRequestWithToken(`/Settings/TeamDeleteMember?userid=${userid}`);
}

export const teamInviteMember = async (data) => {
    return await sendPostRequestWithToken(`/Settings/TeamInviteMember`, data);
}

export const completeTeamInvite = async (data) => {
    return await sendPostRequestWithToken(`/auth/CompleteTeamInvite`, data);
}

export const createEvent = async (data) => {
    return await sendPostRequestWithToken(`/CalendarEvent/Add`, data);
}

export const updateEvent = async (data) => {
    return await sendPostRequestWithToken(`/CalendarEvent/Update`, data);
}

export const isGoogleConnected = async (data) => {
    return await sendPostRequestWithToken(`/ExternalIntegration/GoogleIsConnected`, data);
}

export const removeEvent = async (data) => {
    return await sendPostRequestWithToken(`/CalendarEvent/Remove`, data);
}

export const getAllEvents = async (data) => {
    return await sendPostRequestWithToken(`/CalendarEvent/GetList`, data);
}

export const resendTeamInvite = async (data) => {
    return await sendPostRequestWithToken(`/Settings/TeamResendInviteMember`, data);
}

export const createCustomerPortalUrl = async (returnUrl) => {
    return await sendgetRequestWithToken(`/GWSubscription/CustomerPortal?returnUrl=${returnUrl}`);
}

export const getCurrentSubscriptionState = async () => {
    return await sendgetRequestWithToken(`/GWSubscription/GetCurrentSubscriptionState`);
}

export const switchToSubscription = async (code, isAnnual, returnUrl) => {
    return await sendgetRequestWithToken(`/GWSubscription/SwitchToSubscription?code=${code}&isAnnual=${isAnnual}&returnUrl=${returnUrl}`);
}

export const cancelCurrentSubscription = async () => {
    return await sendgetRequestWithToken(`/GWSubscription/CancelCurrentSubscription`);
}

export const searchCustomer = async (searchString) => {
    return await sendgetRequestWithToken(`/CustomerStripe/Search?searchstring=${searchString}`);
}

export const addInvoice = async (data) => {
    return await sendPostRequestWithToken(`/InvoiceStripe/AddInvoice`, data);
}

export const getInvoice = async (invoiceId) => {
    return await sendgetRequestWithToken(`/InvoiceStripe/GetInvoice?invoiceId=${invoiceId}`);
}

export const changeInvoiceCustomer = async (data) => {
    return await sendPostRequestWithToken(`/InvoiceStripe/ChangeInvoiceCustomer`, data);
}

export const searchProduct = async (searchString, priceType) => {
    return await sendgetRequestWithToken(`/ProductStripe/Search?searchstring=${searchString}&priceType=${priceType}`);
}

export const addLineToInvoice = async (data) => {
    return await sendPostRequestWithToken(`/InvoiceStripe/AddLine`, data);
}

export const removeLineFromInvoice = async (data) => {
    return await sendPostRequestWithToken(`/InvoiceStripe/RemoveLine`, data);
}

export const updateLineOfInvoice = async (data) => {
    return await sendPostRequestWithToken(`/InvoiceStripe/UpdateLine`, data);
}

export const searchProductCoupons = async (lastId) => {
    return await sendgetRequestWithToken(`/ProductCouponStripe/Search?lastid=${lastId || ''}`);
}

export const updateInvoice = async (data) => {
    return await sendPostRequestWithToken(`/InvoiceStripe/UpdateInvoice`, data);
}

export const updateInvoiceDiscount = async (data) => {
    return await sendPostRequestWithToken(`/InvoiceStripe/UpdateInvoiceDiscount`, data);
}

export const updateInvoiceTaxRate = async (data) => {
    return await sendPostRequestWithToken(`/InvoiceStripe/UpdateInvoiceTaxRate`, data);
}

export const memoUpdate = async (data) => {
    return await sendPostRequestWithToken(`/InvoiceStripe/UpdateInvoiceDescription`, data);
}

export const removeDraftInvoice = async (data) => {
    return await sendPostRequestWithToken(`/InvoiceStripe/RemoveDraftInvoice`, data);
}

export const voidInvoice = async (data) => {
    return await sendPostRequestWithToken(`/InvoiceStripe/VoidInvoice`, data);
}

export const sendInvoice = async (data) => {
    return await sendPostRequestWithToken(`/InvoiceStripe/SendInvoice`, data);
}

export const getInvoices = async (status, data) => {
    return await sendPostRequestWithToken(`/InvoiceStripe/GetAll?type=${status}`, data);
}

export const duplicateInvoices = async (data) => {
    return await sendPostRequestWithToken(`/InvoiceStripe/DuplicateInvoice`, data);
}

export const getPaymentSettings = async () => {
    return await sendgetRequestWithToken(`/Settings/GetPaymentsSetting`);
}

export const updatePaymentsSetting = async (data) => {
    return await sendPostRequestWithToken(`/Settings/UpdatePaymentsSetting`, data);
}

export const getIssuingBalance = async () => {
    return await sendgetRequestWithToken(`/AccountCard/GetIssuingBalance`);
}

export const getCardDetails = async (data) => {
    return await sendPostRequestWithToken(`/AccountCard/GetDetail`, data);
}

export const globalSearch = async (searchString) => {
    return await sendgetRequestWithToken(`/global/search?searchstring=${searchString}`);
}

export const getIndividualCardDetail = async (data) => {
    return await sendPostRequestWithToken(`/AccountCard/GetCardDetail`, data);
}

export const cardStatusChange = async (data) => {
    return await sendPostRequestWithToken(`/AccountCard/UpdateCardStatus`, data);
}

export const revealCardCvc = async (data) => {
    return await sendPostRequestWithToken(`/AccountCard/RevealCardCvc`, data);
}

export const updateSpendingLimit = async (data) => {
    return await sendPostRequestWithToken(`/AccountCard/UpdateCardSpending`, data);
}

export const getCardHolders = async (searchString) => {
    return await sendgetRequestWithToken(`/AccountCard/GetCardHolders?searchstring=${searchString}`);
}

export const createCard = async (data) => {
    return await sendPostRequestWithToken(`/AccountCard/CreateCard`, data);
}

export const createLinkAccountSession = async () => {
    return await sendgetRequestWithToken(`/AccountCard/CreateLinkAccountSession`);
}

export const RefreshLinkAccountData = async (data) => {
    return await sendPostRequestWithToken(`/AccountCard/RefreshLinkAccountData`, data);
}

export const GetLinkAccountData = async (data) => {
    return await sendPostRequestWithToken(`/AccountCard/GetLinkAccountData`, data);
}

export const GetDashboardInitialData = async (data) => {
    return await sendPostRequestWithToken(`/DashboardStripe/GetDashboard`, data);
}

export const GetDashboardNextPage = async (data) => {
    return await sendPostRequestWithToken(`/DashboardStripe/GetDashboardNextPage`, data);
}

export const getTransactions = async (data, pageSize, lastId) => {
    return await sendPostRequestWithToken(`/TransactionStripe/GetTransactionsForAccount?pagesize=${pageSize}&lastid=${lastId}`, data);
}

export const getTransactionsNextPage = async (data) => {
    return await sendPostRequestWithToken(`/TransactionStripe/GetTransactionsNextPage`, data);
}

export const pageLoadTransactions = async (data) => {
    return await sendPostRequestWithToken(`/TransactionStripe/GetTransactions`, data);
}

export const dislinkAccount = async (data) => {
    return await sendPostRequestWithToken(`/AccountCard/DisconnectLinkAccount`, data);
}

export const cardTransactions = async (data, pageSize, lastId) => {
    return await sendPostRequestWithToken(`/AccountCard/GetCardTransactions?pagesize=${pageSize}&lastid=${lastId}`, data);
}

export const getIndividualTransaction = async (data) => {
    return await sendPostRequestWithToken(`/TransactionData/GetTransactionData`, data);
}

export const getExtraTransactionData = async (data) => {
    return await sendPostRequestWithToken(`/TransactionData/GetExtraTransactionData`, data);
}

export const getDataOfPopup = async (data, isAccounting, transactionPaylod) => {
    return await sendPostRequestWithToken(`/TransactionData/GetDataOfPopup?isForAccounting=${isAccounting || false}&fetchTransactionPayload=${transactionPaylod || false}`, data);
}
export const saveDataOfPopup = async (data) => {
    return await sendPostRequestWithToken(`/TransactionData/SaveDataOfPopup`, data);
}


export const updateTransactionData = async (data) => {
    return await sendPostRequestWithToken(`/TransactionData/UpdateTransactionData`, data);
}

export const AddTransactionAttachment = async (data) => {
    return await sendPostRequestWithToken(`/TransactionData/AddTransactionAttachment`, data);
}

export const uploadFile = async (data) => {
    return await sendPostRequestWithToken(`/misc/PostFile`, data);
}

export const resetPassword = async (data) => {
    return await sendPostRequestWithToken(`/auth/ResetPassword`, data);
}

export const resetPasswordWithCode = async (data) => {
    return await sendPostRequestWithToken(`/auth/CompleteResetPassword`, data);
}

export const sendInvoiceManually = async (data) => {
    return await sendPostRequestWithToken(`/InvoiceStripe/SendInvoiceManual`, data);
}

export const markManuallyInvoiceAsPaid = async (data) => {
    return await sendPostRequestWithToken(`/InvoiceStripe/PayInvoice`, data);
}

export const makeCardAsDefault = async (data) => {
    return await sendPostRequestWithToken(`/AccountCard/SetDefaultCard`, data);
}

export const addRecipient = async (data) => {
    return await sendPostRequestWithToken(`/TransactionSend/AddRecipient`, data);
}

export const searchRecipients = async (pageSize, lastId, searchStr) => {
    return await sendgetRequestWithToken(`/TransactionSend/SearchRecipient?pagesize=${pageSize}&lastId=${lastId}&searchstring=${searchStr}`);
}

export const getRecipient = async (data) => {
    return await sendPostRequestWithToken(`/TransactionSend/GetRecipient`, data);
}

export const updateRecipient = async (data) => {
    return await sendPostRequestWithToken(`/TransactionSend/UpdateRecipient`, data);
}

export const deleteRecipient = async (data) => {
    return await sendPostRequestWithToken(`/TransactionSend/DeleteRecipient`, data);
}

export const deleteBulkCustomer = async (data) => {
    return await sendPostRequestWithToken(`/Contacts/BulkDelete`, data);
}

export const uploadPaySomeoneDocument = async (data) => {
    return await sendPostRequestWithToken(`/TransactionSend/TryOcrDocument`, data);
}

export const sendPayment = async (data) => {
    return await sendPostRequestWithToken(`/TransactionSend/Send`, data);
}

export const deleteTransactionAttachment = async (data) => {
    return await sendPostRequestWithToken(`/TransactionData/RemoveTransactionAttachment`, data);
}

export const getPaymentLinks = async (pageSize, lastId) => {
    return await sendgetRequestWithToken(`/PaymentLinKStripe/GetList?pagesize=${pageSize}&lastId=${lastId}`);
}

export const deactivePaymentLink = async (data) => {
    return await sendPostRequestWithToken(`/PaymentLinKStripe/DeactivatePaymentLink`, data);
}

export const searchProductPaymentLink = async (searchString, pricingModel, priceType) => {
    return await sendgetRequestWithToken(`/ProductStripe/Search?searchstring=${searchString}&pricingmodel=${pricingModel}&priceType=${priceType || ""}`);
}

export const addPaymentLink = async (data) => {
    return await sendPostRequestWithToken(`/PaymentLinKStripe/AddPaymentLink`, data);
}

export const addSubscription = async (data) => {
    return await sendPostRequestWithToken(`/PaymentSubscriptionStripe/AddSubscription`, data);
}

export const getSubscriptionsByStatus = async (pageSize, lastId, status) => {
    return await sendgetRequestWithToken(`/PaymentSubscriptionStripe/GetList?pagesize=${pageSize}&lastid=${lastId}&status=${status}`);
}

export const getSubscription = async (data) => {
    return await sendPostRequestWithToken(`/PaymentSubscriptionStripe/GetSubscription`, data);
}

export const cancelSubscription = async (data) => {
    return await sendPostRequestWithToken(`/PaymentSubscriptionStripe/CancelSubscription`, data);
}

export const removeSubscriptionLine = async (data) => {
    return await sendPostRequestWithToken(`/PaymentSubscriptionStripe/RemoveLine`, data);
}

export const updateSubscriptionLine = async (data) => {
    return await sendPostRequestWithToken(`/PaymentSubscriptionStripe/UpdateLine`, data);
}

export const AddSubscriptionLine = async (data) => {
    return await sendPostRequestWithToken(`/PaymentSubscriptionStripe/AddLine`, data);
}

export const GetPaymentMethods = async () => {
    return await sendgetRequestWithToken(`/Settings/GetPaymentMethods`);
}

export const CloseAccount = async (data) => {
    return await sendPostRequestWithToken(`/Settings/RemoveAccount`, data);
}

export const AddPayoutBankAccount = async (data) => {
    return await sendPostRequestWithToken(`/PayoutStripe/AddPayoutBankAccount`, data);
}

export const RequestFinancialAccount = async (data) => {
    return await sendPostRequestWithToken(`/StripeConnect/RequestFinancialAccount`, data);
}

export const getIdentityVerifySession = async () => {
    return await sendgetRequestWithToken(`/StripeConnect/GetIdentityVerifySession`);
}

export const removedNotification = async (data) => {
    return await sendPostRequestWithToken(`/DashboardStripe/UpdateFeatureStuff`, data);
}

export const updateTodoDone = async (data) => {
    return await sendPostRequestWithToken(`/DashboardStripe/UpdateTodoDone`, data);
}


export const getAccountDetails = async (data, isExternalAccountAdded) => {
    return await sendPostRequestWithToken(`/AccountCard/GetAccountsSetting?newExternalAccountAdded=${isExternalAccountAdded || ''}`,
        data);
}

export const getIndividualAccountDetails = async (data) => {
    return await sendPostRequestWithToken(`/AccountCard/GetAccountDetailsSetting`, data);
}

export const syncBankAccountWithAccounting = async (data) => {
    return await sendPostRequestWithToken(`/accounting/BankAccounts/SyncBankAccountWithAccounting`, data);
}

export const createFinancialAccount = async (data) => {
    return await sendPostRequestWithToken(`/auth/CreateFinancialAccount`, data);
}

export const checkOpenAccountEligibility = async (data) => {
    return await sendPostRequestWithToken(`/AccountCard/CheckEligibility`, data);
}

export const cancelCard = async (data) => {
    return await sendPostRequestWithToken(`/AccountCard/CancelCard`, data);
}

export const getNextPageTransaction = async (data) => {
    return await sendPostRequestWithToken(`/TransactionStripe/GetTransactionsNextPage`, data);
}

export const UpdatePayoutSchedule = async (data) => {
    return await sendPostRequestWithToken(`/settings/UpdatePayoutSchedule`, data);
}

export const getStatement = async (data, fileType) => {
    return await sendPostRequestWithToken(`/TransactionStripe/GetStatement?filetype=${fileType}`, data);
}

export const getCOAList = async (data, pageSize, lastId) => {
    return await sendPostRequestWithToken(`/Accounting/COA/GetList?pagesize=${pageSize}&lastid=${lastId}`, data);
}

export const searchCOAList = async (searchStr) => {
    return await sendPostRequestWithToken(`/Accounting/COA/Search?searchstring=${searchStr}`, {});
}

export const getSingleCOAAccount = async (coaId) => {
    return await sendPostRequestWithToken(`/Accounting/COA/GetDetail?coaId=${coaId}`, {});
}

export const getCOATransactions = async (data, coaId) => {
    return await sendPostRequestWithToken(`/Accounting/COA/GetGLedger`, data);
}
export const exportCOATransactions = async (data, fileType) => {
    return await sendPostRequestWithToken(`/Accounting/COA/exportGLedger?filetype=${fileType}`, data);
}

export const exportAllCOATransactions = async (data, fileType) => {
    return await sendPostRequestWithToken(`/Accounting/COA/ExportAllGLedger?filetype=${fileType}`, data);
}

export const exportAllCOA = async (data, fileType) => {
    return await sendPostRequestWithToken(`/Accounting/COA/ExportList?filetype=${fileType}`, data);
}

export const getCreateAccountMetaData = async (data) => {
    return await sendPostRequestWithToken(`/Accounting/COA/GetCreateAccountMetadata`, data);
}

export const addNewCOA = async (data) => {
    return await sendPostRequestWithToken(`/Accounting/COA/AddAccount`, data);
}

export const defaultSearchCOA = async (searchStr) => {
    return await sendPostRequestWithToken(`/Accounting/COA/SearchDefault?searchstring=${searchStr}`, {});
}

export const getReconcileAccounts = async () => {
    return await sendPostRequestWithToken(`/Accounting/Reconcile/GetAccountList`, {});
}

export const getReconcileTransactions = async (data) => {
    return await sendPostRequestWithToken(`/Accounting/Reconcile/GetList`, data);
}

export const exportReconcileTransactions = async (data) => {
    return await sendPostRequestWithToken(`/Accounting/Reconcile/ExportList`, data);
}


export const searchCouterParty = async (searchStr) => {
    return await sendgetRequestWithToken(`/Accounting/Main/SearchCounterparty?searchstring=${searchStr}`);
}

export const getTaxRate = async () => {
    return await sendPostRequestWithToken(`/Accounting/TaxRate/GetList`, {});
}

export const bookTransaction = async (data) => {
    return await sendPostRequestWithToken(`/Accounting/Reconcile/BookTransaction`, data);
}

export const cancelTransaction = async (data) => {
    return await sendPostRequestWithToken(`/Accounting/Reconcile/CancelBookedTransaction`, data);
}


export const addTaxRate = async (data) => {
    return await sendPostRequestWithToken(`/Accounting/TaxRate/Add`, data);
}

export const GetPortalSetup = async (data) => {
    return await sendPostRequestWithToken(`/Studio/PortalSetup/Get`, data);
}

export const SavePortalSetup = async (data) => {
    return await sendPostRequestWithToken(`/Studio/PortalSetup/Save`, data);
}

export const updateTaxRate = async (data) => {
    return await sendPostRequestWithToken(`/Accounting/TaxRate/Update`, data);
}

export const enableAccounting = async (data) => {
    return await sendPostRequestWithToken(`/Accounting/Main/EnableAccounting`, data);
}

export const syncFeedAccounts = async (data, id) => {
    return await sendPostRequestWithToken(`/Accounting/BankAccounts/SyncBankAccountFeed?id=${id}&syncPrevious=false`, data);
}

export const getBalanceSheet = async (data) => {
    return await sendPostRequestWithToken(`/Accounting/Reports/BalanceSheet`, data);
}

export const getReportsOverview = async (data) => {
    return await sendPostRequestWithToken(`/Accounting/Reports/Overviewbymonth`, data);
}

export const getProfitLossReport = async (data) => {
    return await sendPostRequestWithToken(`/Accounting/Reports/ProfitLoss`, data);
}

export const getExcelReportOfProfitLoss = async (type, data) => {
    return await sendPostRequestWithToken(`/Accounting/Reports/ProfitLoss?exportType=${type}`, data);
}

export const getExcelReportOfBalanceSheet = async (type, data) => {
    return await sendPostRequestWithToken(`/Accounting/Reports/BalanceSheet?exportType=${type}`, data);
}

export const addCashAccount = async (data) => {
    return await sendPostRequestWithToken(`/AccountCard/UpsertCashAccount`, data);
}


export const getFinancialAccountDetails = async (data) => {
    return await sendPostRequestWithToken(`/AccountCard/GetFinancialAccountDetail`, data);
}

export const downloadAttachment = async (data) => {
    return await sendPostRequestWithToken(`/emails/DownloadAttachment`, data);
}

export const sendEmail = async (data) => {
    return await sendPostRequestWithToken(`/emails/SendEmail`, data);
}

export const updateCard = async (data) => {
    return await sendPostRequestWithToken(`/AccountCard/UpdateCard`, data);
}

export const getEmails = async (data) => {
    return await sendPostRequestWithToken(`/emails/GetEmails`, data);
}


export const getAllCheckList = async (data) => {
    return await sendPostRequestWithToken(`/studio/CheckList/Get`, data);
}

export const addCheckList = async (data) => {
    return await sendPostRequestWithToken(`/studio/CheckList/Upsert`, data);
}

export const addCheckListField = async (data) => {
    return await sendPostRequestWithToken(`/studio/CheckList/UpsertField`, data);
}

export const deleteCheckList = async (id) => {
    return await sendPostRequestWithToken(`/studio/CheckList/Delete?id=${id}`, {});
}

export const removeFieldFromDb = async (id) => {
    return await sendPostRequestWithToken(`/studio/CheckList/DeleteField?id=${id}`, {});
}

export const updatedFieldsSequence = async (data, id) => {
    return await sendPostRequestWithToken(`/studio/CheckList/UpdateFieldsSequence?checkListId=${id}`, data);
}

export const getAllContracts = async (data, id) => {
    return await sendPostRequestWithToken(`/studio/Contract/Get`, data);
}

export const getAllDropdownContracts = async (searchStr = '') => {
    return await sendPostRequestWithToken(`/studio/Contract/SearchContract?searchString=${searchStr}`, {});
}

export const getSingleContractById = async (id) => {
    return await sendPostRequestWithToken(`/studio/Contract/GetById?contractId=${id}`, {});
}

export const addContract = async (data) => {
    return await sendPostRequestWithToken(`/studio/Contract/Upsert`, data);
}

export const updateContract = async (data) => {
    return await sendPostRequestWithToken(`/studio/Contract/Upsert`, data);
}

export const deleteContractStudio = async (id) => {
    return await sendPostRequestWithToken(`/studio/Contract/Delete?id=${id}`, {});
}

export const getAllQuestionnaire = async (data) => {
    return await sendPostRequestWithToken(`/studio/Questionnaire/Get`, data);
}

export const addQuestionnaire = async (data) => {
    return await sendPostRequestWithToken(`/studio/Questionnaire/Upsert`, data);
}

export const updateQuestionnaire = async (data) => {
    return await sendPostRequestWithToken(`/studio/Questionnaire/Upsert`, data);
}

export const deleteQuestionnaire = async (id) => {
    return await sendPostRequestWithToken(`/studio/Questionnaire/Delete?id=${id}`, {});
}

export const getAllEmailsStudio = async (data) => {
    return await sendPostRequestWithToken(`/studio/EmailTemplate/Get`, data);
}

export const addEmailStudio = async (data) => {
    return await sendPostRequestWithToken(`/studio/EmailTemplate/Upsert`, data);
}

export const updateEmailTemplate = async (data) => {
    return await sendPostRequestWithToken(`/studio/EmailTemplate/Upsert`, data);
}

export const deleteEmailTemplate = async (id) => {
    return await sendPostRequestWithToken(`/studio/EmailTemplate/Delete?id=${id}`, {});
}

export const getAllEmbededForm = async (data) => {
    return await sendPostRequestWithToken(`/studio/EmbeddedForm/Get`, data);
}

export const addEmbededForm = async (data) => {
    return await sendPostRequestWithToken(`/studio/EmbeddedForm/Upsert`, data);
}

export const updateEmbeddedForm = async (data) => {
    return await sendPostRequestWithToken(`/studio/EmbeddedForm/Upsert`, data);
}

export const deleteEmbededForm = async (id) => {
    return await sendPostRequestWithToken(`/studio/EmbeddedForm/Delete?id=${id}`, {});
}

export const disconnectGoogleEmails = async (id) => {
    return await sendPostRequestWithToken(`/ExternalIntegration/GoogleDisconnect`, {});
}

export const getContactEvents = async (data) => {
    return await sendPostRequestWithToken(`/Contacts/GetContactEvents`, data);
}

export const getContactPayments = async (data) => {
    return await sendPostRequestWithToken(`/contacts/GetContactPayments`, data);
}

export const getContactsContracts = async (data) => {
    return await sendPostRequestWithToken(`/studio/Contract/GetContactContractList`, data);
}

export const sendContactEmail = async (data) => {
    return await sendPostRequestWithToken(`/contacts/SendEmail`, data);
}

export const addContactQuestionnaire = async (data) => {
    return await sendPostRequestWithToken(`/studio/Questionnaire/AddContactQuestionnaire`, data);
}

export const getContactQuestionnairesList = async (data) => {
    return await sendPostRequestWithToken(`/studio/Questionnaire/GetContactQuestionnairesList`, data);
}


export const updateContactQuestionnaire = async (data) => {
    return await sendPostRequestWithToken(`/studio/Questionnaire/UpdateContactQuestionnaire`, data);
}


export const deleteContactQuestionnaire = async (data, id) => {
    return await sendPostRequestWithToken(`/studio/Questionnaire/DeleteContactQuestionnaire?id=${id}`, data);
}

//

export const deleteContactContract = async (data, id) => {
    return await sendPostRequestWithToken(`/studio/Contract/DeleteContactContract?id=${id}`, data);
}

export const addContactContract = async (data) => {
    return await sendPostRequestWithToken(`/studio/Contract/AddContactContract`, data);
}


export const updateContactContract = async (data) => {
    return await sendPostRequestWithToken(`/studio/Contract/updateContactContract`, data);
}

export const setContactDocuments = async (data) => {
    return await sendPostRequestWithToken(`/contacts/SaveDocument`, data);
}

export const getContactDocuments = async (data) => {
    return await sendPostRequestWithToken(`/contacts/GetContactDocuments`, data);
}

export const deleteBulkProducts = async (data) => {
    return await sendPostRequestWithToken(`/ProductStripe/BulkRemove`, data);
}

export const getDropdownTemplates = async (data) => {
    return await sendPostRequestWithToken(`/studio/Contract/GetTemplates`, data);
}

export const resolveTemplate = async (data) => {
    return await sendPostRequestWithToken(`/studio/Contract/ResolveTemplate`, data);
}

export const sendContract = async (data) => {
    return await sendPostRequestWithToken(`/studio/Contract/AddContactContract`, data);
}

export const deleteContract = async (contractId, data) => {
    return await sendPostRequestWithToken(`/studio/Contract/DeleteContactContract?id=${contractId}`, data);
}

export const getSingleContract = async (contractId, data) => {
    return await sendPostRequestWithToken(`/studio/Contract/GetContactContractDetailById?id=${contractId}`, data);
}

export const resendContract = async (contractId, data) => {
    return await sendPostRequestWithToken(`/studio/Contract/ResendContactContract?id=${contractId}`, data);
}

export const updateSignature = async (contractId, data) => {
    return await sendPostRequestWithToken(`/studio/Contract/AddContractSignatureForCompany?id=${contractId}`, data);
}

export const getQuestionnaireTemplate = async (data) => {
    return await sendPostRequestWithToken(`/studio/Questionnaire/GetTemplates`, data);
}

export const getQuestionnaireTemplateData = async (data) => {
    return await sendPostRequestWithToken(`/studio/Questionnaire/GetTemplateData`, data);
}

export const sendQuestionnaire = async (data) => {
    return await sendPostRequestWithToken(`/studio/Questionnaire/AddContactQuestionnaire`, data);
}

export const deleteSingleQuestionnaire = async (questionnaireId, data) => {
    return await sendPostRequestWithToken(`/studio/Questionnaire/DeleteContactQuestionnaire?id=${questionnaireId}`, data);
}

export const resendQuestionnaire = async (questionnaireId, data) => {
    return await sendPostRequestWithToken(`/studio/Questionnaire/ResendContactQuestionnaire?id=${questionnaireId}`, data);
}

export const getSingleQuestionnaire = async (questionnaireId, data) => {
    return await sendPostRequestWithToken(`/studio/Questionnaire/GetContactQuestionnaireDetailById?id=${questionnaireId}`, data);
}

export const enableStudio = async (data) => {
    return await sendPostRequestWithToken(`/Studio/StudioMain/EnableStudio`, data);
}

export const enableBookKeeping = async (data) => {
    return await sendPostRequestWithToken(`/Accounting/Main/EnableBookkeeping`, data);
}

export const activateCompanyFeature = async (data) => {
    return await sendPostRequestWithToken(`/ComapnyFeatures/Activate`, data);
}

export const deActivateCompanyFeature = async (data) => {
    return await sendPostRequestWithToken(`/ComapnyFeatures/Deactivate`, data);
}

export const enableProperty = async (data) => {
    return await sendPostRequestWithToken(`/property/EnableProperty`, data);
}

export const sortFeatures = async (data) => {
    return await sendPostRequestWithToken(`/ComapnyFeatures/FeaturesSort`, data);
}

export const disableProperty = async (data) => {
    return await sendPostRequestWithToken(`/property/disableProperty`, data);
}

export const disableStudio = async (data) => {
    return await sendPostRequestWithToken(`/Studio/StudioMain/DisableStudio`, data);
}

export const disableAccounting = async (data) => {
    return await sendPostRequestWithToken(`/Accounting/Main/DisableAccounting`, data);
}

export const disableBookkeeping = async (data) => {
    return await sendPostRequestWithToken(`/Accounting/Main/DisableBookkeeping`, data);
}

export const deleteCashAccount = async (data) => {
    return await sendPostRequestWithToken(`/AccountCard/DeleteCashAccount`, data);
}

export const embededFormGetDetail = async (token, data) => {
    return await sendPostRequestWithToken(`/studio/Direct/EmbeddedFormGetDetail?token=${token}`, data);
}

export const sendEmbededForm = async (token, data) => {
    return await sendPostRequestWithToken(`/studio/Direct/EmbeddedFormResponse?token=${token}`, data);
}

export const getProspectList = async (data) => {
    return await sendPostRequestWithToken(`/contacts/GetProspectList`, data);
}

export const deleteProspect = async (data) => {
    return await sendPostRequestWithToken(`/contacts/DeleteProspect?id=${data?.id}`, {});
}

export const convertProspectToCustomer = async (data) => {
    return await sendPostRequestWithToken(`/contacts/ConvertProspectToStripeCustomer`, data);
}

export const deleteBulkCards = async (data) => {
    return await sendPostRequestWithToken(`/AccountCard/BulkRemoveCards`, data);
}

//portal
export const portalCustomerLogin = async (data, code, email) => {
    return await sendPostRequest('/CustomerPortal/CustomerLogin?code=' + code + '&email=email', data);
}

export const portalCustomerGetProfile = async (data) => {
    return await sendPostRequestWithPortalToken('/CustomerPortal/GetProfile', data);
}


export const portalCustomerGetInvoices = async (data) => {
    return await sendPostRequestWithPortalToken('/CustomerPortal/GetInvoices', data);
}


export const portalCustomerGetContracts = async (data) => {
    return await sendPostRequestWithPortalToken('/CustomerPortal/GetContracts', data);
}

export const portalCustomerGetSignedContracts = async (data) => {
    return await sendPostRequestWithPortalToken('/CustomerPortal/GetSignedContracts', data);
}

export const portalCustomerGetQuestionnaires = async (data) => {
    return await sendPostRequestWithPortalToken('/CustomerPortal/GetQuestionnaires', data);
}

export const portalCustomerGetDocuments = async (data) => {
    return await sendPostRequestWithPortalToken('/CustomerPortal/GetDocuments', data);
}

export const portalCustomerGetStripePortalLink = async (data) => {
    return await sendPostRequestWithPortalToken('/CustomerPortal/GetStripePortalLink', data);
}

export const getAllQuotes = async (pageSize, lastId) => {
    return await sendgetRequestWithToken(`/QuoteStripe/GetAll?pagesize=${pageSize}&lastid=${lastId}`);
}

export const cancelQuote = async (data) => {
    return await sendPostRequestWithToken('/QuoteStripe/CancelQuote', data);
}

export const finalizeQuote = async (data) => {
    return await sendPostRequestWithToken('/QuoteStripe/FinalizeQuote', data);
}

export const duplicateQuote = async (data) => {
    return await sendPostRequestWithToken('/QuoteStripe/DuplicateQuote', data);
}

export const reviseQuote = async (data) => {
    return await sendPostRequestWithToken('/QuoteStripe/ReviseQuote', data);
}

export const addQuote = async (data) => {
    return await sendPostRequestWithToken('/QuoteStripe/AddQuote', data);
}

export const getQuote = async (quoteId) => {
    return await sendgetRequestWithToken(`/QuoteStripe/GetQuote?quoteId=${quoteId}`);
}

export const updateQuote = async (data) => {
    return await sendPostRequestWithToken(`/QuoteStripe/UpdateQuote`, data);
}


export const pdfQuote = async (data) => {
    return await sendPostRequestWithToken(`/QuoteStripe/PdfQuote`, data);
}


export const acceptQuote = async (data) => {
    return await sendPostRequestWithToken(`/QuoteStripe/AcceptQuote`, data);
}

export const searchProductTaxRates = async (lastId) => {
    return await sendgetRequestWithToken(`/ProductTaxRateStripe/Search?pagesize=30&lastid=${lastId || ''}`);
}

export const searchProductTaxRates_DefaultOption = async (lastId) => {
    return await sendgetRequestWithToken(`/ProductTaxRateStripe/Search?withDefault=true&pagesize=30&lastid=${lastId || ''}`);
}

export const addNewTransaction = async (data) => {
    return await sendPostRequestWithToken(`/TransactionData/AddDataOfPopup`, data);
}

export const getCompanyTextSettings = async (data) => {
    return await sendPostRequestWithToken(`/Settings/GetCompanyTaxSetting`, data);
}

export const getCompanyStripeBalanceSettings = async (data) => {
    return await sendPostRequestWithToken(`/Settings/GetCompanyStripeBalanceSettings`, data);
}

export const updateCompanyStripeBalanceSettings = async (data) => {
    return await sendPostRequestWithToken(`/Settings/UpdateCompanyStripeBalanceSettings`, data);
}

export const updateCompanyTextSettings = async (data) => {
    return await sendPostRequestWithToken(`/Settings/UpdateCompanyTaxSetting`, data);
}


export const getIntegrationSettings = async () => {
    return await sendgetRequestWithToken(`/Settings/GetIntegrationSettings`);
}

export const updateZapierIntegrationSettings = async (isZapierIntegrationOn) => {
    return await sendgetRequestWithToken(`/Settings/UpdateZapierIntegrationSettings?isZapierIntegrationOn=${(isZapierIntegrationOn ? 1 : 0)}`);
}

export const deleteCOAAccount = async (id, data) => {
    return await sendPostRequestWithToken(`/Accounting/COA/DeleteAccount?id=${id}`, data);
}

export const updateCOAAccount = async (data) => {
    return await sendPostRequestWithToken(`/Accounting/COA/UpdateAccount`, data);
}

export const getAlerts = async (data) => {
    return await sendPostRequestWithToken(`/alertevent/getlist`, data);
}

export const markAlertAsRead = async (id) => {
    return await sendgetRequestWithToken(`/alertevent/MarkAsRead?id=${id}`);
}

export const deleteAlert = async (id) => {
    return await sendgetRequestWithToken(`/alertevent/Delete?id=${id}`);
}

export const getPipelineList = async (data) => {
    return await sendPostRequestWithToken(`/ContactPipeline/GetList`, data);
}

export const updateColumnOrder = async (data) => {
    return await sendPostRequestWithToken(`/ContactPipeline/UpdateColumnsOrder`, data);
}

export const updateColumnCardsOrderInSameLane = async (data) => {
    return await sendPostRequestWithToken(`/ContactPipeline/UpdateDealsOrder`, data);
}

export const updateColumnCardsOrderAcrossLane = async (data) => {
    return await sendPostRequestWithToken(`/ContactPipeline/MoveDeal`, data);
}

export const getPipelineColumnsList = async (data) => {
    return await sendPostRequestWithToken(`/ContactPipeline/GetColumnsList`, data);
}

export const addPipelineColumn = async (data) => {
    return await sendPostRequestWithToken(`/ContactPipeline/AddColumn`, data);
}

export const updatePipelineColumn = async (data) => {
    return await sendPostRequestWithToken(`/ContactPipeline/UpdateColumn`, data);
}

export const deletePipelineColumn = async (id, data) => {
    return await sendPostRequestWithToken(`/ContactPipeline/RemoveColumn?pipelineColumnId=${id}`, data);
}

export const getSingleDeal = async (id) => {
    return await sendPostRequestWithToken(`/ContactPipeline/GetDealById?pipelineDealId=${id}`, {});
}

export const addDeal = async (data) => {
    return await sendPostRequestWithToken(`/ContactPipeline/AddDeal`, data);
}

export const updateDeal = async (data) => {
    return await sendPostRequestWithToken(`/ContactPipeline/UpdateDeal`, data);
}

export const removeDeal = async (id, data) => {
    return await sendPostRequestWithToken(`/ContactPipeline/RemoveDeal?pipelineDealId=${id}`, data);
}

export const convertDealToCustomer = async (data) => {
    return await sendPostRequestWithToken(`/ContactPipeline/ConvertDealToContact`, data);
}

export const getAllPropertiesList = async (data) => {
    return await sendPostRequestWithToken(`/Property/GetList`, data);
}

export const addProperty = async (data) => {
    return await sendPostRequestWithToken(`/Property/Add`, data);
}

export const getSingleProperty = async (data) => {
    return await sendPostRequestWithToken(`/Property/Get`, data);
}

export const updateSingleProperty = async (data) => {
    return await sendPostRequestWithToken(`/Property/Update`, data);
}

export const deleteSingleProperty = async (data) => {
    return await sendPostRequestWithToken(`/Property/Remove`, data);
}

export const propertyLendersList = async (data) => {
    return await sendPostRequestWithToken(`/Property/GetPropertyLenders`, data);
}

export const individualLenderDetail = async (data) => {
    return await sendPostRequestWithToken(`/Property/GetLenderDetails`, data);
}

export const applyForLoanFromLender = async (data) => {
    return await sendPostRequestWithToken(`/Property/ApplyForLoan`, data);
}

export const updateLoan = async (data) => {
    return await sendPostRequestWithToken(`/Property/UpdateLoan`, data);
}

export const multiplePropertyDelete = async (data) => {
    return await sendPostRequestWithToken(`/Property/BulkRemove`, data);
}

export const getAllPropertyDocuments = async (data) => {
    return await sendPostRequestWithToken(`/Property/GetDocuments`, data);
}

export const addPropertyDocument = async (data) => {
    return await sendPostRequestWithToken(`/Property/SaveDocument`, data);
}

export const updatePropertyDocument = async (data) => {
    return await sendPostRequestWithToken(`/Property/SaveDocument`, data);
}

export const searchPropertyDocument = async (data) => {
    return await sendPostRequestWithToken(`/Property/SearchDocuments`, data);
}

export const sendLoanApplicationWithPdf = async (data) => {
    return await sendPostRequestWithToken(`/Property/SendLoanApplication`, data);
}

export const generatePropertyLink = async (data) => {
    return await sendPostRequestWithToken(`/Property/GeneratePropertyLink`, data);
}

export const getPropertyLink = async (data) => {
    return await sendPostRequestWithToken(`/CustomerPortal/GetPropertyLinkData`, data);
}

export const signUpWithUserDetails = async (data, skipToken) => {
    return await sendPostRequestWithToken(`/auth/CompleteSignUpV4`, data, "application/json", skipToken);
}

export const createChatChannel = async (data) => {
    return await sendPostRequestWithToken(`/Property/CreateAndGetLenderChannel`, data);
}

export const listAllChannelsHandler = async (data) => {
    return await sendPostRequestWithToken(`/Property/ListAllChannels`, data);
}

export const joinSingleChannel = async (data) => {
    return await sendPostRequestWithToken(`/Property/JoinChannel`, data);
}

export const createConversationWithAI = async (text) => {
    return await sendPostRequestWithToken(`/OpenAI/CreateConversation`, { Text: text });
}

export const replyToConversationWithAI = async (conversationId, text) => {
    return await sendPostRequestWithToken(`/OpenAI/ReplyConversation`, { ConversationId: conversationId, Text: text });
}

export const getPredefinedPrompts = async () => {
    return await sendgetRequestWithToken(`/OpenAI/PredefinedPrompts`);
}

export const getPromptsList = async (data) => {
    return await sendPostRequestWithToken(`/Prompts/GetList`, data);
}

export const addPrompt = async (data) => {
    return await sendPostRequestWithToken(`/Prompts/Add`, data);
}

export const updatePrompt = async (data) => {
    return await sendPostRequestWithToken(`/Prompts/Update`, data);
}

export const deletePrompt = async (id) => {
    return await sendPostRequestWithToken(`/Prompts/Delete?companyPromptId=${id}`, {});
}

export const createAIContract = async (data) => {
    return await sendPostRequestWithToken(`/Prompts/CreateAIContract`, data);
}

export const getToDOSpaces = async (data) => {
    return await sendPostRequestWithToken(`/ToDoTask/GetSpacesList`, data);
}

export const addToDOSpaces = async (data) => {
    return await sendPostRequestWithToken(`/ToDoTask/AddSpace`, data);
}

export const updateToDOSpaces = async (data) => {
    return await sendPostRequestWithToken(`/ToDoTask/UpdateSpace`, data);
}

export const deleteToDoSpace = async (id) => {
    return await sendPostRequestWithToken(`/ToDoTask/RemoveSpace?toDoSpaceId=${id}`, {});
}

export const getToDoPipelineColumns = async (data) => {
    return await sendPostRequestWithToken(`/ToDoTask/GetColumnsList`, data);
}

export const addToDoPipelineColumn = async (data) => {
    return await sendPostRequestWithToken(`/ToDoTask/AddColumn`, data);
}

export const updateToDoPipelineColumn = async (data) => {
    return await sendPostRequestWithToken(`/ToDoTask/UpdateColumn`, data);
}

export const deleteToDoPipelineColumn = async (id) => {
    return await sendPostRequestWithToken(`/ToDoTask/RemoveColumn?toDoPipelineId=${id}`, {});
}

export const updateSortOrderToDoPipeline = async (data) => {
    return await sendPostRequestWithToken(`/ToDoTask/UpdateColumnsOrder`, data);
}

export const getAllTasks = async (data) => {
    return await sendPostRequestWithToken(`/ToDoTask/GetAll`, data);
}

export const updateToDoTasksOrder = async (data) => {
    return await sendPostRequestWithToken(`/ToDoTask/UpdateTaskOrder`, data);
}

export const updateToDoAcrossLaneOrder = async (data) => {
    return await sendPostRequestWithToken(`/ToDoTask/MoveTask`, data);
}

export const addToDOTask = async (data) => {
    return await sendPostRequestWithToken(`/ToDoTask/AddTask`, data);
}

export const updateToDOTask = async (data) => {
    return await sendPostRequestWithToken(`/ToDoTask/UpdateTask`, data);
}

export const deleteToDOTask = async (id) => {
    return await sendPostRequestWithToken(`/ToDoTask/RemoveTask?toDoTaskId=${id}`, {});
}

export const getSingleToDoTask = async (id) => {
    return await sendPostRequestWithToken(`/ToDoTask/GetTaskById?toDoTaskId=${id}`, {});
}

export const addToDoTaskComment = async (data) => {
    return await sendPostRequestWithToken(`/ToDoTask/AddComment`, data);
}

export const deleteToDoTaskComment = async (id) => {
    return await sendPostRequestWithToken(`/ToDoTask/RemoveComment?toDoTaskHistoryId=${id}`, {});
}

export const updateToDoTaskComment = async (data) => {
    return await sendPostRequestWithToken(`/ToDoTask/UpdateComment`, data);
}

export const getSendInvoiceEmailDetail = async (id) => {
    return await sendgetRequestWithToken(`/InvoiceStripe/GetSendInvoiceDetail?invoiceId=${id}`);
}

export const sendInvoiceWithEmailPopup = async (data) => {
    return await sendPostRequestWithToken(`/InvoiceStripe/SendInvoiceWithPopup`, data);
}

export const getSendSubscriptionEmailDetail = async (data) => {
    return await sendPostRequestWithToken(`/PaymentSubscriptionStripe/GetSendSubscriptionDetail`, data);
}

export const sendSubscriptionWithEmailPopup = async (data) => {
    return await sendPostRequestWithToken(`/PaymentSubscriptionStripe/SendSubscription`, data);
}

export const getCapitalLinkAccount = async (data) => {
    return await sendPostRequestWithToken(`/Capital/GetLinkAccounts`, data);
}

export const getCapitalApplication = async (data) => {
    return await sendPostRequestWithToken(`/Capital/GetRecentCapitalApplied`, data);
}

export const startCapitalApplication = async (data) => {
    return await sendPostRequestWithToken(`/Capital/CapitalApplication`, data);
}

export const cancelCapitalApplication = async (data) => {
    return await sendPostRequestWithToken(`/Capital/CancelApplication`, data);
}

export const acceptCapitalOffer = async (data) => {
    return await sendPostRequestWithToken(`/Capital/AcceptOffer`, data);
}

export const getCapitalLendersVentureList = async (data) => {
    return await sendPostRequestWithToken(`/Capital/GetLenderList`, data);
}

export const convertLenderToProspect = async (data) => {
    return await sendPostRequestWithToken(`/Capital/ConvertLenderToProspect`, data);
}

export const getVentureCapitalPipeline = async (data) => {
    return await sendPostRequestWithToken(`/Capital/GetList`, data);
}

export const getVentureCapitalColumnList = async (data) => {
    return await sendPostRequestWithToken(`/Capital/GetColumnsList`, data);
}

export const udpateVentureCapitalColumnsInSameLane = async (data) => {
    return await sendPostRequestWithToken(`/Capital/UpdateProspectOrder`, data);
}

export const udpateVentureCapitalColumnsAcrossLane = async (data) => {
    return await sendPostRequestWithToken(`/Capital/MoveProspect`, data);
}

export const addProspect = async (data) => {
    return await sendPostRequestWithToken(`/Capital/AddProspect`, data);
}

export const updateProspect = async (data) => {
    return await sendPostRequestWithToken(`/Capital/UpdateProspect`, data);
}

export const removeProspect = async (id, data) => {
    return await sendPostRequestWithToken(`/Capital/RemoveProspect?pipelineProspectId=${id}`, data);
}

export const updateVentureCapitalColumnOrder = async (data) => {
    return await sendPostRequestWithToken(`/Capital/UpdateColumnsOrder`, data);
}

export const addCapitalVenturePipelineColumn = async (data) => {
    return await sendPostRequestWithToken(`/Capital/AddColumn`, data);
}

export const updateCapitalVenturePipelineColumn = async (data) => {
    return await sendPostRequestWithToken(`/Capital/UpdateColumn`, data);
}

export const deleteVentureCapitalPipelineColumn = async (id, data) => {
    return await sendPostRequestWithToken(`/Capital/RemoveColumn?pipelineColumnId=${id}`, data);
}

export const getMemberDetailsById = async (id) => {
    return await sendgetRequestWithToken(`/Settings/GetTeamMemberById?userId=${id}`);
}

export const updateMemberDetailsById = async (data) => {
    return await sendPostRequestWithToken(`/Settings/UpdateTeamMember`, data);
}